import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {setLanguage} from "../../actions";
import i18next from "i18next";

const mapStateToProps = (state) => {
    return {
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: (lang) => dispatch(setLanguage(lang))
    }
}

class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleLanguage = async (l) => {
        this.props.setLanguage(l)
    }

    render() {
        return (
            <div className="form-group">
                <div className='langChooser'>
                    <button 
                        className={'lang ' + (this.props.language === 'it' ? 'selected' : '')} 
                        onClick={this.handleLanguage.bind(this,'it')}
                        title={i18next.t("italiano")}
                    >
                        <img src="/img/it.svg" />
                    </button>
                    <button 
                        className={'lang ' + (this.props.language === 'en' ? 'selected' : '')} 
                        onClick={this.handleLanguage.bind(this,'en')}
                        title={i18next.t("inglese")}
                    >
                        <img src="/img/gb.svg" />
                    </button>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageSelector));