import React from 'react';
import './input.css';

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.blur = this.blur.bind(this);
    }
    random(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() *  charactersLength));
        }
        return result;
    }
    blur() {
        if(this.props.blur) {
            this.props.blur();
        }
    }
    render() {
        const label_class = (this.props.label && this.props.label!="") ? "" : "hidden";
        const id = this.props.id ? this.props.id : this.random(20);
        const disabled = this.props.disabled===true ? this.props.disabled : false;
        const readonly = this.props.readonly===true ? this.props.readonly : false;
        return (
            <div className="form-group">
                <label className={label_class}>{this.props.placeholder}</label>
                <input type={this.props.type} className="form-control" readOnly={readonly} disabled={disabled} id={id} name={this.props.name} placeholder={this.props.placeholder} onBlur={this.blur} onChange={this.props.change} value={this.props.value} min={this.props.min} max={this.props.max} step={this.props.step}/>
            </div>
        )
    }
}

export default Input;