export function setCurrentCheck(payload) {
    return { type: 'SET_CURRENT_CHECK', payload }
};
export function setCompany(payload) {
    return { type: 'SET_COMPANY', payload }
};
export function setUserRole(payload) {
    return { type: 'SET_ROLE', payload }
};
export function setTripData(payload) {
    return { type: 'SET_TRIP', payload }
};
export function setDailyTripData(payload) {
    return { type: 'SET_DAILY_TRIP', payload }
};
export function setGroupTripData(payload) {
    return { type: 'SET_GROUP_TRIP', payload }
};
export function setLastSearch(payload) {
    return { type: 'SET_SEARCH', payload }
};
export function setDatesHistory(payload){
    return {type: 'SET_DATES_HISTORY', payload}
}
export function setBeginDateCalendar(payload){
    return {type: 'SET_BEGIN_DATE_CALENDAR', payload}
}
export function setCalendarDate(payload){
    return {type: 'SET_CALENDAR_DATE', payload}
}
export function setLanguage(payload) {
    return { type: 'CHANGE_LANGUAGE', payload}
}
export function setDays(payload) {
    return { type: 'SET_DAYS', payload}
}
export function setStartTimeline(payload){
    return { type: 'SET_START_TIMELINE', payload}
}
export function setEndTimeline(payload){
    return { type: 'SET_END_TIMELINE', payload}
}
export function setTripsOfTheDay(payload){
    return { type: 'SET_TRIPS_OF_THE_DAY', payload}
}
export function setPendings(payload){
    return { type: 'SET_PENDINGS', payload}
}
export function setUser(payload){
    return { type: 'SET_USER', payload}
}